import { IUserDataJwtPayload } from '~/types/auth';

export const mockUserId = '00001111-AAAA-AAAA-AAAA-000011112222';
export const mockUserCompanyId = '00001111-CCCC-CCCC-CCCC-000011112222';
export const mockUserPhoneNumber = '+79513332211';

export const mockJwtTokenPayload = {
  userId: mockUserId,
  phoneNumber: mockUserPhoneNumber,
  companyId: mockUserCompanyId,
  role: 'OWNER'
}

export const mockUserDataJwtPayload = {
  companyId: mockUserCompanyId,
  username: mockUserPhoneNumber,
  role: 'OWNER',
  iat: Date.now() - 10000,
  exp: Date.now() + 10000,
}