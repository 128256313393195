import { IncomingMessage, OutgoingMessage } from 'http'
import { H3Event } from 'h3'
import { useStore } from '~/store';
import { defineNuxtPlugin } from '#app';

export interface NuxtServerInitOptions {
  event: H3Event,
  req: IncomingMessage,
  res: OutgoingMessage,
  cookies?: Record<string, string>,
  cookiesRaw?: string;
}

export default defineNuxtPlugin(async (nuxtApp) => {
  console.log('init.store.ts plugin')

  const store = useStore();

  await store.initStore({ cookiesRaw: document.cookie })
})

