import { ProfeatUI, ColorsThemes } from "@profeat/ui-kit";
import { ref } from "vue";
import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  const theme = ref(ColorsThemes.grass); // Default theme

  nuxtApp.vueApp.use(ProfeatUI, {
    theme: theme.value,
  });

  nuxtApp.vueApp.provide("theme", theme);
  nuxtApp.provide("theme", theme);
});
