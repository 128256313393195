import { defineStore } from "pinia";
import { jwtDecode } from 'jwt-decode';
import type { IUserDataJwtPayload } from '~/types/auth';
import { cookieUtils } from '~/utils/cookies';
import { mockUserDataJwtPayload } from '~/mocks/auth.mock';

interface State {
  user: IUserDataJwtPayload | null;
}

const isLocalMode = true;

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    user: null,
  }),
  actions: {
    async getUser(rawCookies: string) {
      const parsedCookies = cookieUtils.parseCookies(rawCookies || '')

      if (isLocalMode) {
        console.log('-- Local mode is running! --')
        this.setUser(mockUserDataJwtPayload)
        return;
      }

      if (parsedCookies['jwt-access-token']) {
        if (isJwtExpired(parsedCookies['jwt-access-token'])) {
          return
        }

        const jwt = jwtDecode(parsedCookies['jwt-access-token']) as any

        this.setUser({
          companyId: jwt.companyId,
          username: jwt?.phoneNumber,
          iat: jwt.iat,
          exp: jwt.exp,
          role: jwt?.role
        })

        return
      }

      this.user = null;
    },
    setUser (user: IUserDataJwtPayload) {
      this.user = user;
    }
  },
});
