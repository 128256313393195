import { acceptHMRUpdate, defineStore } from 'pinia'
import { useAuthStore } from '~/store/auth.store';
export const useStore = defineStore('root', {
  actions: {
    async initStore (params: { cookiesRaw: string }) {
      const store = useStore()

      await store.initAppStore(store, params.cookiesRaw)
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async initAppStore (store: any, cookiesRaw: string | undefined) {
      const authStore = useAuthStore()

      await Promise.all([
        authStore.getUser(cookiesRaw as string),
      ])
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}
